import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/idday/idday.png"
import idday1 from "../../../assets/images/portfolio_items/idday/idday1.png"
import idday2 from "../../../assets/images/portfolio_items/idday/idday2.png"
import idday3 from "../../../assets/images/portfolio_items/idday/idday3.png"
import idday4 from "../../../assets/images/portfolio_items/idday/idday4.png"
import idday5 from "../../../assets/images/portfolio_items/idday/idday5.png"
import idday6 from "../../../assets/images/portfolio_items/idday/idday6.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Iron Deficiency Day - Web Project '} 
    description="Discover more about Iron Deficiency Day's web project and PPC campaign: give a look at ICON's portfolio now!"
    canonical={'https://icon-worldwide.com/works/iron-deficiency-day'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
            <div className="title"><h1>Iron Deficiency Day<span></span></h1>
            <h2>website and PPC campaign to raise awaireness</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Iron Deficiency Day, occurring yearly on November 26th, is designed to raise awareness. ICON developed the “Meet the Symptoms” concept which introduces the common every day symptoms of the condition. The website and PPC campaign reach millions of consumers across 22 affiliate counties.</p>
        <div className="portfolio-images">
            <img src={idday1} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
            <img src={idday4} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
            <img src={idday6} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
            <img src={idday2} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
            <img src={idday3} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
            <img src={idday5} alt=" Iron Deficiency Day web project, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Iron Deficiency Day web project  "/>
        </div>
        <WorkFooter previous="project-firefly" next="frankfurt-school"/>
        </div>
    </div>
</div>